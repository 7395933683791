<template>
  <div class="novel-project-manager">
    <div>
      <!-- <put-project :params="copyParams" :selected="selected" ></put-project> -->
      <router-view  :params="copyParams" :selected="selected"></router-view>
    </div>
  </div>
</template>
<script>
// import putProject from '@/views/statistics/today/index'
import { formatTime } from '@/assets/js/utils.js'
export default {
  data () {
    return {
      selected: 999,
      copyParams: {}
    }
  },
  props: ['params'],
  craeted () {
    this.init()
  },
  methods: {
    init () {
      if (!this.params) {
        this.$set(
          this.params,
          'putData',
          this.params.putData
            ? this.params.putData
            : {
              selectTime: [
                formatTime(Date.now(), 'yyyy-MM-dd'),
                formatTime(Date.now(), 'yyyy-MM-dd')
              ]
            }
        )
      } else {
        this.copyParams = JSON.parse(JSON.stringify(this.params))
      }
    }
  },
  components: {
    // putProject
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-menu {
  width: auto !important;
}
/deep/ .el-menu-item.is-active {
  border-right:0;
}
.novel-project-manager {
  width:calc(100% - 220px);
  // margin: 0 16px 10px;
  box-sizing: border-box;
  .novel-project-manager-header {
    background: #fff;
    padding: 10px 20px;
    .choose-type {
    }
  }
  .novel-project-info {
    margin-top: 10px;
    box-sizing: inherit;
    padding: 10px 20px;
    background: #fff;
  }
}
</style>
