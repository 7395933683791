import { render, staticRenderFns } from "./novel-project-manager.vue?vue&type=template&id=24702c05&scoped=true&"
import script from "./novel-project-manager.vue?vue&type=script&lang=js&"
export * from "./novel-project-manager.vue?vue&type=script&lang=js&"
import style0 from "./novel-project-manager.vue?vue&type=style&index=0&id=24702c05&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "24702c05",
  null
  
)

export default component.exports